import React from 'react'
import { Link } from "react-router-dom";

import Logo from '../../images/DAZY_logo_white_alt.svg'

function NavBar() {
  return (
      <div className='nav-bar'>
        <div className='place-logo'>
          <Link to='/'><img alt='dazy-logo' src={Logo}/></Link>
        </div>
        <nav className='nav-options'>
        <ul>
          <li>(More updates to come)</li>
          {/* <li><Link to='/about'>About</Link></li> */}
          {/* <li><Link to='/projects'>Works</Link></li> */}
          {/* <li><Link to='/'>Explore</Link></li> */}
          {/* <li><Link to='/contact'>Contact</Link></li> */}
        </ul>
        </nav>
      </div>
  )
}

export default NavBar