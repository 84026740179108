module.exports = {
  projects: [
    {
      id: 1,
      title: 'RightOn! Education',
      images: [
        '/images/projects/large_righton.jpg',
      ],
      webUrl: 'https://www.rightoneducation.com',
      deliverables: [
        'Responsive Web Design', 'Web Developemnt', 'Brand Identity', 'User Testing'
      ],
      technology: [
        'Figma', 'Node.js', 'JavaScript/React','HTML/CSS/SASS', 'Amazon S3 Storage Service'
      ],
      summary: 'RightOn Education is an EdTech startup company based in San Francisco and Wellington. The team is developing a fun classroom-based math game activity for high-school students. The game focuses on cultivating a positive culture of error that encourages students to learn through identifying, and understanding common misconceptions in solving math problems. ',
      // outcomes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan sed enim ac pulvinar. Etiam a placerat nibh, in elementum enim. Integer eget ultrices odio. Integer ultricies porttitor ante, vel feugiat lacus laoreet nec. Ut dapibus tincidunt urna, quis fermentum eros viverra vel. Praesent elementum ante eu felis posuere tempus. Mauris vel mi sit amet elit consectetur tempor sit amet non libero. In interdum suscipit est, at ornare erat dictum at. Quisque sed tempor lorem. Sed placerat est non orci varius, ut mollis mauris ornare. Sed vitae bibendum risus. Ut.'
    },
    {
      id: 2,
      title: 'Susan C. Tunney',
      images: 
        '/images/projects/large_tunney_website.jpg'
      ,
      webUrl: 'https://www.susanctunney.com',
      deliverables: [
        'Web Design', 'Branding', 'Photography', 'Design Consultation', 'Social Media Integration'
      ],
      technology: [
        'Wordpress template – The client wants a website that\'s easy for herself to maintain, as she\'s already familiar with blogging on Wordpress, we decided to keep it on the same plateform.'
      ],
      summary: "Susan is an American author currently residing in New Zealand. We re-designed her website and boosted her online presence to stay engaged with her readers worldwide. ",
      // outcomes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan sed enim ac pulvinar. Etiam a placerat nibh, in elementum enim. Integer eget ultrices odio. Integer ultricies porttitor ante, vel feugiat lacus laoreet nec. Ut dapibus tincidunt urna, quis fermentum eros viverra vel. Praesent elementum ante eu felis posuere tempus. Mauris vel mi sit amet elit consectetur tempor sit amet non libero. In interdum suscipit est, at ornare erat dictum at. Quisque sed tempor lorem. Sed placerat est non orci varius, ut mollis mauris ornare. Sed vitae bibendum risus. Ut.'
    },
    {
      id: 3,
      title: 'Practising Well',
      images: '/images/projects/practising_well.jpg'
      ,
      webUrl: 'https://www.youtube.com/watch?v=oCoftmRM640',
      deliverables: [
        'Storyboarding', 
        'Filming and Audio Recording', 
        'Video Editing', 
        'Brand Identity'
      ],
      technology: [
        'Adobe Premier Pro', 'Adobe After Effects'
      ],
      summary: 'I developed storyboards, filmed, edited and recorded narration for the New Zealand Law Society\'s Practising Well initiative; a series of articles and interviews with legal practitioners to raise awareness of mental health issues in the sector.',
      // outcomes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan sed enim ac pulvinar. Etiam a placerat nibh, in elementum enim. Integer eget ultrices odio. Integer ultricies porttitor ante, vel feugiat lacus laoreet nec. Ut dapibus tincidunt urna, quis fermentum eros viverra vel. Praesent elementum ante eu felis posuere tempus. Mauris vel mi sit amet elit consectetur tempor sit amet non libero. In interdum suscipit est, at ornare erat dictum at. Quisque sed tempor lorem. Sed placerat est non orci varius, ut mollis mauris ornare. Sed vitae bibendum risus. Ut.'
    },
    {
      id: 4,
      title: 'Publication design for digital & print',
      images: [
        '/images/projects/magazine_design.jpg',
      ],
      deliverables: [
        'Photography', 
        'Infographics',
        'Layout & Typographic Design', 
        'Liaising with print company'
      ],
      technology: [
        'Adobe Creative Cloud'
      ],
      summary: 'While working as a in-house graphic designer at the New Zealand Law Society, I helped co-created more than one hundred magazines throughout the year; I worked on cover design, feature stories, photography, infographics and illustrations.',
      // outcomes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan sed enim ac pulvinar. Etiam a placerat nibh, in elementum enim. Integer eget ultrices odio. Integer ultricies porttitor ante, vel feugiat lacus laoreet nec. Ut dapibus tincidunt urna, quis fermentum eros viverra vel. Praesent elementum ante eu felis posuere tempus. Mauris vel mi sit amet elit consectetur tempor sit amet non libero. In interdum suscipit est, at ornare erat dictum at. Quisque sed tempor lorem. Sed placerat est non orci varius, ut mollis mauris ornare. Sed vitae bibendum risus. Ut.'
    },
    // {
    //   id: 5,
    //   title: 'Collection of visual design works',
    //   images: [
    //     '/images/profile_andy.jpeg',
    //   ],
    //   deliverables: [],
    //   technology: [],
    //   summary: 'A collection of other visual works I have created over the years, the wide-ranging types of works including annual reports, coroperate identity, .',
    //   // outcomes: ''
    // },
  ]
}