import React from 'react'
import { Pause, Pace, WindupChildren } from "windups";

function LandingPage() {
  return (
      <div className='wrapper landing-intro'>
            <div className='content'>
              <div className="intro-welcome-animation">
              <WindupChildren >
                <Pace getPace={(char) => (char === " " ? 200 : 40)}>
                  <Pause ms={50}/>
                    <p>{"design,"}</p>
                  <Pause ms={50}/>
                    <p>{"develop,"}</p>
                  <Pause ms={50}/>
                    <p>{"deliver"}</p>
                </Pace>
              </WindupChildren>
                <span id="faux-cursor"></span>
              </div>
              <div className="intro-self">
                <div className="animate-intro">
                  <div id="line-one">
                    <div className="bar"> {"Hello! My name is Daz."}
                    </div>
                    <div>{"Hello! My name is Daz."}</div>
                  </div>
                  <div id="line-two">
                  <div className="bar">{"I am a multi-disciplinary visual designer,"}</div>
                  <div>{"I am a multi-disciplinary visual designer,"} </div>
                  </div>
                  <div id="line-three">
                      <div className="bar">{"currently based in Taiwan & New Zealand."}
                      </div>
                      <div>
                        {"currently based in Taiwan & New Zealand."} 
                      <div> 
                        <span id="get-in-touch">
                        <a href="/">
                          {"Get in touch."}
                        </a>
                        <div id="animated-bar"></div>
                        </span>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  )
}

export default LandingPage;

