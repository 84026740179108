// import React, {useState} from 'react';
import React from 'react';
import './App.scss';
// import axios from 'axios'
// import {browserHistory} from "react-router";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import NavBar from './components/molecules/NavBar.js'
import LandingPage from './components/organism/LandingPage.js';
// import AboutPage from './components/organism/AboutPage';
// import PortfolioPage from './components/organism/PortfolioPage';
// import ContactPage from './components/organism/ContactPage';
import Footer from './components/organism/Footer';

import projectDB from '../src/_Db/projects'

// const encode = (data) => {
//   return Object.keys(data)
//     .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//     .join("&");
// }

function App() {
  // const [values, setValues] = useState({
  //   firstname:'',
  //   lastname:'',
  //   email:'',
  //   mobileNum: '',
  //   message:'',
  //   isLoading: false
  // })
  // function handleChange(e) {
  //   setValues({
  //     ...values,
  //     [e.target.name]: e.target.value
  //   })
  // }
  // using Netflify
  // function handleSubmit(e) {
  //   e.preventDefault()
  //   fetch("/", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //       body: encode({ "form-name": "contact", ...values})
  //     })
  //       .then(() => {
  //         setTimeout(() => {
  //           alert("Thank you for your email! I will get back to you in the next couple of days.")
  //           resetForm()
  //         }, 3500)
  //       })
  //       .catch(error => alert(error));
  //   };
  // using Axios
    // function handleSubmit(e) {
    //   e.preventDefault()
    //   // axios is a http library
    //   axios({ 
    //     method: "POST", 
    //     url:"http://localhost:3002/send", 
    //     data: values
    //   }).then((response)=>{
    //     if (response.data.status === 'success'){
    //       alert("Thank you – I will get back to you shortly!");
    //       resetForm()
    //     }else if(response.data.status === 'fail'){
    //       alert("There seems to be an error. Try again!")
    //     }
    //   })
    // }
  // function resetForm() {
  //   setValues({
  //     firstname: '',
  //     lastname: '',
  //     email: '',
  //     mobileNum: '',
  //     message: ''
  //   })
  // }
  // function setLoadingTrue() {
  //   setValues({
  //     isLoading: true
  //   })
  // }
  
  return (
    <Router>
      <div className="App">
        <div className='container'>
        <NavBar />
        <Switch>
           <Route exact path='/' 
            render={() => (
            <LandingPage projects={projectDB.projects}/>
            )}
          />
          {/* <Route path='/about' component={AboutPage}/> */}
          {/* <Route path='/projects' 
            render={() => (
            <PortfolioPage projectDb={projectDB.projects}/>
            )}
          /> */}
          {/* <Route path='/contact' 
            render={() => (
            <ContactPage 
              firstname={values.firstname} 
              lastname={values.lastname} 
              email={values.email} 
              mobileNum={values.mobileNum} 
              message={values.message} 
              handleChange={handleChange} 
              handleSubmit={handleSubmit}
              setLoadingTrue={setLoadingTrue}
              isLoading={values.isLoading}
              />
            )}
          /> */}
        </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;