import React from 'react'

import linkedIn from '../../images/icons/linkedin.svg'

function Footer () {
  return(
    <footer>
      <div>
      hello@dazy.nz
      </div>
      <div>
        <a href="https://www.linkedin.com/in/dazyang/" target='blank'>
          <img alt='linkedIn' src={linkedIn} />
        </a>
      </div>
      <div id="copyright">
      All rights reserved &copy; design and developed by me.
      </div>     
    </footer>
  )
}

export default Footer